<template>
  <v-card
    v-if="this.measurements.N != undefined"
    class="mx-auto mt-5 d-flex align-center flex-column"
  >
    <v-card-title class="pb-b"><h4>Measurements</h4></v-card-title>
    <v-card-text>
      <v-row justify="center">
        <v-col cols="6">
          <v-row>
            <v-col>
              <v-card max-width="200" class="mx-auto">
                <v-card-text class="text-center">
                  Desorganization Index
                  <p class="text-h5 text-primary">
                    {{ index.toFixed(2) }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col>
              <v-img
                v-if="this.graph_lscz != undefined"
                :src="this.graph_lscz"
                width="500"
                class="mx-auto"
              >
              </v-img>
              <v-img
                v-if="this.graph_index != undefined"
                :src="this.graph_index"
              >
              </v-img>
              <p>
                The measured disorganization index was
                <strong>{{ index.toFixed(2) }}%</strong>, represented in the
                graph by the purple line.
              </p>
              <p>
                The measured disorganization index was higher than
                <strong>{{ analysis.ecdf_schiz.toFixed(2) }}%</strong> of those
                obtained by the schizophrenia group in our database (z-score =
                {{ analysis.zscore_schiz.toFixed(2) }}).
              </p>
              <p>
                The measured disorganization index was higher than
                <strong>{{ analysis.ecdf_bipolar.toFixed(2) }}%</strong> of
                those obtained by the bipolar group in our database (z-score =
                {{ analysis.zscore_bipolar.toFixed(2) }}).
              </p>
              <p>
                The measured disorganization index was higher than
                <strong>{{ analysis.ecdf_control.toFixed(2) }}%</strong> of
                those obtained by the control group in our database (z-score =
                {{ analysis.zscore_control.toFixed(2) }}).
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-img
            v-if="this.graph_histogram != undefined"
            :src="this.graph_histogram"
          >
          </v-img>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-row>
            <v-col v-for="(measurement, index) in measurements" :key="index">
              <v-card>
                <v-card-text class="text-center">
                  {{ index }}
                  <p
                    class="text-h5 text-primary"
                    v-if="Number.isInteger(measurement)"
                  >
                    {{ measurement }}
                  </p>
                  <p v-else class="text-h5 text-primary">
                    {{ measurement.toFixed(2) }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="6">
              <v-img v-if="this.graph != undefined" :src="this.graph"> </v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center flex-column text-center">
        <v-col>
          <v-btn rounded color="primary" @click="startAgain">
            <v-icon left>fa-keyboard</v-icon>
            Try again
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from "vuex"

export default {
  name: "GraphResults",
  computed: mapState({
    measurements: state => state.speechgraph.measurements,
    index: state => state.speechgraph.index,
    analysis: state => state.speechgraph.analysis,
    graph: state => state.speechgraph.graph,
    graph_index: state => state.speechgraph.graph_index,
    graph_histogram: state => state.speechgraph.graph_histogram,
    graph_lscz: state => state.speechgraph.graph_lscz,
  }),
  methods: {
    startAgain() {
      this.$store.dispatch("speechgraph/start")
    },
  },
}
</script>
